<template>
  <div>
    <app-menu />
    <cities-list />
    <app-header :fixed="isFixed" />
    <div class="default-wrapper" style="min-height: 50vh">
      <slot />
    </div>
    <app-footer />
    <div class="footer-bottom">
      <div class="container">
        <span>Magnum Cash & Carry @2022 {{ $t("allRightsReserved") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";
import { actionTypes as actionTypesCatalog } from "@/store/modules/catalog";
import { eventBus, gtmDataLayer } from "../helpers";
export default {
  name: "DefaultLayout",
  components: {
    AppMenu: () => import("@/components/common/AppMenu.vue"),
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    CitiesList: () => import("@/components/common/CitiesList.vue"),
  },
  data() {
    return {
      isFixed: false,
    };
  },
  computed: {
    ...mapState(["isOpenSelectCity"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
  },
  watch: {
    isOpenSelectCity: {
      handler() {
        if (this.isOpenSelectCity) {
          document.querySelector("body").classList.add("no-scroll");
        } else {
          document.querySelector("body").classList.remove("no-scroll");
        }
      },
    },
  },
  created() {
    this.gtmSubscribe();
  },
  mounted() {
    if (localStorage.getItem("selectedCity")) {
      this.changeSelectedCity(JSON.parse(localStorage.getItem("selectedCity")));
    }
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    }
    this.$router.onReady(() => {
      if (this.$route.params.lang !== this.$i18n.locale) {
        this.$router.replace({
          query: { city_id: this.selectedCity?.id || 2 },
        });
      }
    });
  },
  methods: {
    ...mapActions({ changeLang: actionTypes.changeCurrentLanguage }),
    ...mapActions("cityModule", {
      changeSelectedCity: actionTypesCity.changeSelectedCity,
    }),
    ...mapActions("catalogModule", {
      clearFilters: actionTypesCatalog.clearFilters,
    }),
    gtmSubscribe() {
      eventBus.$on("gtm", (payload) => {
        console.log("EVENT BUS", payload);
        gtmDataLayer(payload);
      });
    },
    changeIsFixed() {
      this.isFixed = true;
    },
  },
};
</script>
