import axios from "axios";
import i18n from "@/plugins/i18n";

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  timeout: 100000,
});
http.defaults.params = {};

http.interceptors.request.use(
  function (config) {
    let lang = i18n.locale;
    if (lang === "kz") {
      lang = "kk";
    }
    config.params["locale"] = lang;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    let lang = i18n.locale;
    if (lang === "kz") {
      lang = "kk";
    }
    response.data.data["related_id"] =
      response.data?.data?.attributes?.localizations?.data[0]?.id;
    if (lang === "kk") {
      if (Array.isArray(response?.data?.data)) {
        response?.data?.data?.map((d) => {
          if (d?.attributes?.localizations?.data[0]) {
            d.related_id = d?.id;
            d.id = d?.attributes?.localizations?.data[0].id;
          }
          return d;
        });
      }
      const localeAttributes =
        response.data?.data?.attributes?.localizations?.data[0]?.attributes;

      if (localeAttributes) {
        for (const [key, val] of Object.entries(localeAttributes)) {
          response.data.data.attributes[key] = val;
        }
      }

      return response;
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default http;
