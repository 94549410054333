import axios from "@/plugins/axios";
import qs from "qs";
export const getShop = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        shop_type: {
          populate: "logo",
        },
        shop_features: {
          populate: "*",
          localizations: {
            populate: {
              logo: "*",
            },
          },
        },
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/shops/${id}?${query}`);
  return response?.data?.data;
};

export const getShopTypes = async () => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/shop-types?${query}`);
  return response?.data?.data;
};

export const getShopFeatures = async () => {
  const query = qs.stringify(
    {
      populate: {
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/shop-features?${query}`);
  return response?.data?.data;
};

export const getShopsByFilters = async (cityId, filters) => {
  let querFyilters = {};
  if (filters?.shopType && !filters.shopType.includes(0)) {
    querFyilters["shop_type"] = { id: { $in: filters.shopType } };
  }
  if (filters?.features) {
    querFyilters["shop_features"] = { id: { $in: filters.features } };
  }
  if (!cityId) {
    cityId = JSON.parse(localStorage.getItem("selectedCity"))?.id;
  }

  const query = qs.stringify(
    {
      populate: {
        shop_type: {
          populate: "logo",
        },
        shop_features: {
          populate: {
            name: "*",
            logo_dark: "*",
          },
        },
        localizations: "*",
      },
      filters: { city: { id: { $eq: cityId } }, ...querFyilters },
    },

    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/shops/?${query}`);
  return response?.data?.data;
};
