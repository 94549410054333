<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$i18n.locale" />
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    FullscreenLayout: () => import("@/layouts/FullscreenLayout.vue"),
    KnivesLayout: () => import("@/layouts/KnivesLayout.vue"),
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
  mounted() {
    console.log(this.layout)
  }
};
</script>
