import axios from "@/plugins/axios";

export const getGooglePlay = async () => {
  const response = await axios.get(`/google-play-link`);
  return response?.data;
};

export const getAppStore = async () => {
  const response = await axios.get(`/app-store-link`);
  return response?.data;
};

export const getAppGallery = async () => {
  const response = await axios.get(`/app-gallery-link`);
  return response?.data;
};

export const getSocialLink = async () => {
  const response = await axios.get(`/social-link`);
  return response?.data?.data;
};

export const getMagnumClubLink = async () => {
  const response = await axios.get(`/magnum-club-link`);
  return response?.data?.data;
};

export const getMagnumGoLink = async () => {
  const response = await axios.get(`/magnum-go-link`);
  return response?.data?.data;
};

export const getMagnumGoGooglePlay = async () => {
  const response = await axios.get(`/magnum-go-playmartket`);
  return response?.data;
};

export const getMagnumGoAppStore = async () => {
  const response = await axios.get(`/magnum-go-app-store`);
  return response?.data;
};
