import Vue from "vue";

export const eventBus = new Vue();

export default {
  getAbsolutePath: (path) => process.env.VUE_APP_API_URL + path,
};

const mapItemsToGtm = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item, index) => {
      return {
        item_name: item.attributes.name, // Названия продукта //name
        item_id: item.id, // идентификатор продукта//id
        price: item.attributes.final_price, //цена//final_price
        currency: "KZT", //валюта//всегда КЗТ
        item_category: payload.category, // Наивысшая категория для товара, по которому  был клик со списка//главная или каталог скидок или страница акции
        item_list_name: payload.listName, // название списка, в котором пользователь совершил клик по товару//discount_type
        item_list_id: item.attributes.discount_type, // Идентификатор списка, в котором пользователь совершил клик по товару//discount_type id
        index: index, // номер товара в списке
        quantity: "1", // количество товара
      };
    });
  }
};

const mapViewProducts = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item, index) => {
      return {
        item_name: item.attributes.name, // Названия продукта //name
        item_id: item.id, // идентификатор продукта//id
        price: item.attributes.final_price, //цена//final_price
        currency: "KZT", //валюта//всегда КЗТ
        discount: item.attributes.discount,
        item_category: item.attributes.category.data.attributes.label, // Наивысшая категория для товара, по которому  был клик со списка//главная или каталог скидок или страница акции
        item_list_name: payload.listName, // название списка, в котором пользователь совершил клик по товару//discount_type
        item_list_id: item.attributes.category.data.id, // Идентификатор списка, в котором пользователь совершил клик по товару//discount_type id
        index: index, // номер товара в списке
      };
    });
  }
};
const mapSelectItem = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item, index) => {
      return {
        discount: item.attributes.discount,
        item_name: item.attributes.name, // Названия продукта //name
        item_id: item.id, // идентификатор продукта//id
        price: item.attributes.final_price, //цена//final_price
        currency: "KZT", //валюта//всегда КЗТ
        item_category: payload.category, // Наивысшая категория для товара, по которому  был клик со списка//главная или каталог скидок или страница акции
        item_list_name: payload.listName, // название списка, в котором пользователь совершил клик по товару//discount_type
        item_list_id: item.attributes.discount_type, // Идентификатор списка, в котором пользователь совершил клик по товару//discount_type id
        index: index, // номер товара в списке
        filter_shop: "Magnum 24/7",
        filter_discount: "Горячие скидки",
      };
    });
  }
};

const mapViewItem = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item, index) => {
      return {
        discount: item.attributes.discount,
        item_name: item.attributes.name, // Названия продукта //name
        item_id: item.id, // идентификатор продукта//id
        price: item.attributes.final_price, //цена//final_price
        currency: "KZT", //валюта//всегда КЗТ
        index: index, // номер товара в списке
      };
    });
  }
};

const mapPromotion = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item) => {
      return {
        promotion_name: item.attributes.header
          ? item.attributes.header
          : item.attributes.label, // Название акции
        promotion_id: item.id ? item.id : "", // id акции. Может быть пустым для не акционных банеров
        creative_slot: payload.slot, // Блок банера
        url_banner: item.attributes.link
          ? item.attributes.link
          : getLink(payload), // URL куда ведет банер
      };
    });
  }
};

const getLink = (payload) => {
  return (
    window.location.origin + window.location.pathname + `${payload.urlName}`
  );
};

/**
 *
 * @param {*} payload {type:string|search|ecommerce, items:type Array, event:string}
 * @returns undefined
 */

export const gtmDataLayer = (payload) => {
  // console.log("START");
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  // console.log("mapPromotion", mapPromotion(payload));
  const term = {
    event: payload.event || "no_event",
  };
  switch (payload.type) {
    case "search":
      term.type_search = payload.typeSearch;
      term.text_search = payload.textSearch;
      // console.log("DL before push", dataLayer);
      dataLayer.push(term);
      // console.log("DL after push", dataLayer);
      return;
    case "ecommerce":
      term.ecommerce = {
        items: mapItemsToGtm(payload),
      };
      break;
    case "shop":
      term.name = payload.name;
      term.city_show = payload.city;
      break;
    // case "select_promotion":
    //   term.ecommerce = {
    //     items: mapBannersToGtm(payload),
    //   };
    //   break;
    case "select_item":
      term.ecommerce = {
        items: mapSelectItem(payload),
      };
      break;
    case "view_item":
      term.ecommerce = {
        items: mapViewItem(payload),
      };
      break;
    case "select_promotion":
      term.ecommerce = {
        items: mapPromotion(payload),
      };
      console.log(mapPromotion(payload));
      break;
    case "view_promotion":
      term.ecommerce = {
        items: mapPromotion(payload),
      };
      break;
    case "view_item_list":
      term.ecommerce = {
        items: mapViewProducts(payload),
      };
      break;
  }
  // console.log("DL before push", dataLayer);
  dataLayer.push(term);
  // console.log("DL after push", dataLayer);
};
