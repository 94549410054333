import { getAllNews, getNewsById } from "@/api/news";

export const mutationTypes = {
  loadAllNewsStart: "mutation/loadAllNewsStart",
  loadAllNewsSuccess: "mutation/loadAllNewsSuccess",
  loadAllNewsFailure: "mutation/loadAllNewsFailure",

  loadNewsByIdStart: "mutation/loadNewsByIdStart",
  loadNewsByIdSuccess: "mutation/loadNewsByIdSuccess",
  loadNewsByIdFailure: "mutation/loadNewsByIdFailure",
};
export const actionTypes = {
  loadAllNews: "action/loadAllNews",
  loadNews: "action/loadNews",
};

export const newsModule = {
  namespaced: true,
  state: () => ({
    isLoading: false,
    error: null,
    allNews: null,
    newsById: null,
  }),

  mutations: {
    [mutationTypes.loadAllNewsStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadAllNewsSuccess](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.allNews = payload;
    },
    [mutationTypes.loadAllNewsFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },

    [mutationTypes.loadNewsByIdStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadNewsByIdSuccess](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.newsById = payload;
    },
    [mutationTypes.loadNewsByIdFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },
  },
  actions: {
    [actionTypes.loadAllNews]({ commit }) {
      commit(mutationTypes.loadAllNewsStart);

      return new Promise((resolve) => {
        getAllNews()
          .then((news) => {
            commit(mutationTypes.loadAllNewsSuccess, news);
            resolve(news);
          })
          .catch((e) => {
            commit(mutationTypes.loadAllNewsFailure, e);
          });
      });
    },

    [actionTypes.loadNews]({ commit }, id) {
      commit(mutationTypes.loadNewsByIdStart);

      return new Promise((resolve) => {
        getNewsById(id)
          .then((news) => {
            commit(mutationTypes.loadNewsByIdSuccess, news);
            resolve(news);
          })
          .catch((e) => {
            commit(mutationTypes.loadNewsByIdFailure, e);
          });
      });
    },
  },
  getters: {
    blockNews: (state) =>
      state.allNews?.length
        ? state.allNews.filter((n) => n.id !== state.newsById.id).slice(0, 4)
        : [],
  },
};
