<template>
  <div class="home">
    <main>
      <home-banner-slider />
      <discount-list />
      <intersect
        v-if="isShowStocksIntersect && stocks.data"
        @enter="stocksIntersect = 'Intersected'"
      >
        <section class="stocks">
          <div class="container">
            <div class="header-block">
              <h2 class="title">
                {{ $t("stock") }}
              </h2>
              <locale-router-link to="stocks" class="header-block__link">{{
                $t("showMore")
              }}</locale-router-link>
            </div>
            <stocks-list v-if="stocks.data" :items="stocks.data" />

            <button
              v-if="!stocks.isLastPage"
              class="stocks__button"
              @click="nextStocksPage"
            >
              {{ $t("seeAll") }}
            </button>
          </div>
        </section>
      </intersect>

      <weekly-products-list :page-size="5" />

      <slider-production />
      <section class="magnum-club">
        <div class="container">
          <locale-router-link to="magnumclub" class="magnum-club__content">
            <div class="magnum-club__left">
              <div class="magnum-club__left-wrapper">
                <div class="magnum-club__header">
                  <img
                    src="@/assets/img/magnum-club/title.jpg"
                    alt="title"
                    class="magnum-club__title"
                    width="149"
                    height="60"
                  />
                  <div class="magnum-club__slogan">
                    {{ $t("BuyWithProfit") }}
                  </div>
                </div>
                <div class="magnum-club__subtitle">
                  {{ $t("yourLoyaltyCard") }}
                </div>
                <div class="magnum-club__btns">
                  <a
                    :href="appStoreLink"
                    class="magnum-club__btn"
                    target="_blank"
                    @click.stop="getClubLink(appStoreLink)"
                  >
                    <img
                      src="@/assets/img/magnum-club/app-store.png"
                      alt="image"
                      width="149"
                      height="41"
                    />
                  </a>
                  <a
                    :href="googlePlayLink"
                    class="magnum-club__btn"
                    target="_blank"
                    @click.stop="getClubLink(googlePlayLink)"
                  >
                    <img
                      src="@/assets/img/magnum-club/google-play.png"
                      alt="image"
                      width="149"
                      height="41"
                    />
                  </a>
                  <a
                    :href="appGalleryLink"
                    class="magnum-club__btn"
                    target="_blank"
                    @click.stop="getClubLink(appGalleryLink)"
                  >
                    <img
                      src="@/assets/img/magnum-club/app-gallery.png"
                      alt="image"
                      width="149"
                      height="41"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="magnum-club__img">
              <img src="@/assets/img/magnum-club/bg.jpg" alt="image" />
            </div>
          </locale-router-link>
        </div>
      </section>

      <div class="magnum-club-mobile">
        <div class="container">
          <div class="magnum-club-mobile__left">
            <div class="magnum-club-mobile__info">
              <img
                src="@/assets/img/magnum-club/title-mobile.svg"
                alt="title"
              />
              <div class="magnum-club-mobile__slogan">
                {{ $t("BuyWithProfit_2") }}
              </div>
              <p class="magnum-club-mobile__descr">
                {{ $t("BuyWithProfit_text") }}
              </p>
              <locale-router-link
                to="magnumclub"
                class="magnum-club-mobile__link"
                >{{ $t("more") }}</locale-router-link
              >
            </div>
            <img
              src="@/assets/img/magnum-club/mobile.png"
              alt="image"
              class="magnum-club-mobile__img"
            />
          </div>

          <div class="magnum-club-mobile__btns">
            <a
              :href="appStoreLink"
              class="magnum-club__btn"
              target="_blank"
              @click.stop="getClubLink(appStoreLink)"
            >
              <img
                src="@/assets/img/magnum-club/app-store.png"
                alt="image"
                width="149"
                height="41"
              />
            </a>
            <a
              :href="googlePlayLink"
              class="magnum-club__btn"
              target="_blank"
              @click.stop="getClubLink(googlePlayLink)"
            >
              <img
                src="@/assets/img/magnum-club/google-play.png"
                alt="image"
                width="149"
                height="41"
              />
            </a>
            <a
              :href="appGalleryLink"
              class="magnum-club__btn"
              target="_blank"
              @click.stop="getClubLink(appGalleryLink)"
            >
              <img
                src="@/assets/img/magnum-club/app-gallery.png"
                alt="image"
                width="149"
                height="41"
              />
            </a>
          </div>
        </div>
      </div>

      <brands-list />

      <shops-map
        v-if="shopsList"
        :is-show-shop-types="true"
        :shops-list="shopsList"
        :key="selectedCity.id"
      />
      <div v-else class="loader-mini__container">
        <div class="loader-mini"></div>
      </div>
      <locale-router-link to="career" tag="section" class="banner-vacancy">
        <img
          class="banner-text-img"
          src="@/assets/img/icons/banner-text.svg"
          alt="banner text"
        />
        <div class="container">
          <div class="banner-vacancy__content">
            <div class="banner-vacancy__header">
              <img
                class="banner-wings"
                src="@/assets/img/icons/banner-wings.svg"
                alt="image"
                width="64"
                height="67"
              />
              <h2>
                {{ $t("buildCareer") }}<span>{{ $t("leaveRequest") }}</span>
              </h2>
            </div>
            <button class="btn-gray">{{ $t("submitApplication") }}</button>
          </div>
        </div>
      </locale-router-link>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getPromos } from "@/api";
import { getShopsByFilters } from "@/api/shop";
import { getGooglePlay, getAppStore, getAppGallery } from "@/api/links";
import Intersect from "vue-intersect";
import { eventBus } from "@/helpers";

export default {
  name: "Home",
  components: {
    HomeBannerSlider: () => import("@/components/sliders/HomeBannerSlider.vue"),
    DiscountList: () => import("@/components/discount/DiscountList.vue"),
    WeeklyProductsList: () =>
      import("@/components/products/WeeklyProductsList.vue"),
    StocksList: () => import("@/components/stocks/StocksList.vue"),
    BrandsList: () => import("@/components/brands/BrandsList.vue"),
    ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
    SliderProduction: () => import("@/components/sliders/SliderProduction.vue"),
    Intersect,
  },
  data() {
    return {
      googlePlayLink: null,
      appStoreLink: null,
      appGalleryLink: null,
      isShowStocksIntersect: false,
      stocks: {
        page: 1,
        pageSize: 3,
        data: null,
        isLastPage: true,
      },
      shopsList: null,
      stocksIntersect: "",
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperBannersRef;
    },
    cityId() {
      return this.$i18n.locale === "kz"
        ? this.selectedCity?.attributes.localizations?.data[0].id
        : this.selectedCity.id;
    },
  },
  watch: {
    stocksIntersect: {
      handler() {
        if (this.stocksIntersect === "Intersected") {
          this.gtmViewPromotion(this.stocks.data);
        }
      },
    },

    selectedCity: {
      handler() {
        getShopsByFilters(this.selectedCity.id).then((shops) => {
          this.shopsList = shops;
        });
        if (!this.stocks.data) {
          getPromos(
            { type: "current" },
            {
              page: this.stocks.page,
              pageSize: this.stocks.pageSize,
            }
          ).then((stocks) => {
            this.stocks.data = stocks.data;
          });
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.isShowStocksIntersect = true;
      this.isShowChefIntersect = true;
    }, 1500);
  },
  created() {
    if (this.selectedCity?.id) {
      getPromos(
        { type: "current" },
        {
          page: this.stocks.page,
          pageSize: this.stocks.pageSize,
        }
      ).then((stocks) => {
        this.stocks.data = stocks.data;
        console.log("stocks", this.stocks.data.length);
        if (this.stocks.data.length > 3) {
          this.stocks.isLastPage = false;
        } else {
          this.stocks.isLastPage = true;
        }
      });
    }

    if (!this.shopsList && this.selectedCity?.id) {
      getShopsByFilters(this.selectedCity?.id).then((shops) => {
        this.shopsList = shops;
      });
    }

    getGooglePlay().then((googlePlay) => {
      this.googlePlayLink = googlePlay.data.attributes.link;
    });
    getAppStore().then((appStore) => {
      this.appStoreLink = appStore.data.attributes.link;
    });
    getAppGallery().then((appGallery) => {
      this.appGalleryLink = appGallery.data.attributes.link;
    });
  },

  destroyed() {
    // eventBus.$off("gtm");
  },
  methods: {
    getClubLink(link) {
      window.location.href = link;
    },

    gtmViewPromotion(items) {
      eventBus.$emit("gtm", {
        event: "view_promotion",
        type: "view_promotion",
        urlName: "stocks",
        slot: "Блок Акции",
        items: [...items],
      });
    },
    nextStocksPage() {
      this.stocks.page++;
      getPromos(
        { type: "current" },
        {
          page: this.stocks.page,
          pageSize: this.stocks.pageSize,
        }
      ).then((stocks) => {
        this.stocks.data = [...this.stocks.data, ...stocks.data];
        this.gtmViewPromotion(stocks.data);
        if (this.stocks.page === stocks.meta.pagination.pageCount) {
          this.stocks.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.loader__container {
  margin-top: 150px;
}
@media (max-width: 1250px) {
  .loader__container {
    margin-top: 100px;
  }
}
@media (max-width: 600px) {
  .loader__container {
    margin-top: 60px;
  }
}
</style>
