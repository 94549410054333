import axios from "@/plugins/axios";
import qs from "qs";

export const getAllProducts = async (page = 1, pageSize = 500, cityId) => {
  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      filters: {
        shops: {
          city: {
            id: { $eq: cityId },
          },
        },
      },
      populate: {
        image: "*",
        localizations: "*",
        category: "*",
        discount_type: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/products?${query}`);
  return response?.data;
};

export const getProductsByFilters = async (
  filters,
  sortBy,
  pagination,
  excludeId,
  cityId
) => {
  const { categories, shopTypes, discountTypes } = filters;
  if (!cityId) {
    cityId =
      localStorage.key("selectedCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectedCity"))?.id
        : 2;
  }

  let queryFilters = { filters: {} };
  if (excludeId) {
    queryFilters.filters["id"] = {
      $ne: excludeId,
    };
  }
  if (categories.length) {
    queryFilters.filters.category = {
      id: {
        $in: categories,
      },
    };
  }
  if (shopTypes?.length) {
    queryFilters.filters.shops = {
      shop_type: {
        id: {
          $in: shopTypes,
        },
      },
    };
  }
  if (discountTypes?.length) {
    queryFilters.filters.discount_type = {
      id: {
        $in: discountTypes,
      },
    };
  }
  if (cityId) {
    if (queryFilters.filters?.shops) {
      queryFilters.filters.shops["city"] = {
        id: { $eq: cityId },
      };
    } else {
      queryFilters.filters["shops"] = {
        city: { id: { $eq: cityId } },
      };
    }
  }
  if (
    !categories.length &&
    !discountTypes.length &&
    !shopTypes.length &&
    !cityId
  ) {
    queryFilters.filters.category = {
      id: { $notNull: true },
    };
  }

  let sort = {};

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] };
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] };
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      populate: {
        image: "*",
        localizations: "*",
        category: "*",
      },
      ...sort,
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    }
  );

  const response = await axios.get(`/products?${query}`);

  return response?.data;
};

export const getProductById = async (id, cityId) => {
  const query = qs.stringify(
    {
      populate: {
        shops: {
          populate: {
            shop_type: {
              populate: {
                logo: "*",
              },
            },
            shop_features: {
              populate: {
                logo_dark: "*",
              },
            },
            city: "*",
          },
        },
        filters: {
          cities: {
            id: { $in: cityId },
          },
        },
        image: "*",
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/products/${id}?${query}`);
  return response?.data?.data;
};

export const getShopProducts = async (id, pagination, sortBy) => {
  let sort = {};

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] };
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] };
  }
  const query = qs.stringify({
    populate: {
      image: "*",
      localizations: "*",
    },
    ...sort,
    filters: {
      shops: {
        id: { $eq: id },
      },
    },
    pagination: {
      page: pagination?.page || 1,
      pageSize: pagination?.pageSize || 20,
    },
  });
  const response = await axios.get(`/products?${query}`);
  return response?.data;
};

export const getShowcases = async (pagination) => {
  const query = qs.stringify({
    pagination: {
      ...pagination,
    },
    populate: {
      image: "*",
      localizations: "*",
      internal_name: "*",
      label: "*",
      showcase_bulks: "*",
      richtext: "*",
    },
  });
  const response = await axios.get(`/showcases?${query}`);
  return response?.data;
};
