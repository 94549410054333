import { getProductsByFilters } from "@/api/products";

export const mutationTypes = {
  addFilter: "mutation/addFilter",
  setFilters: "mutation/setFilters",
  removeFilter: "mutation/removeFilter",
  clearFilters: "mutation/clearFilters",

  addSortBy: "mutation/addSortBy",
  clearSortBy: "mutation/clearSortBy",

  loadProductsStart: "mutation/loadProductsStart",
  loadProductsSuccess: "mutation/loadProductsSuccess",
  loadProductsFailure: "mutation/loadProductsFailure",

  setCategories: "mutation/setCategories",
  setPdfProducts: "mutation/setPdfProducts",
  setAllProducts: "mutation/setAllProducts",
  // setCategorizedProducts: "mutation/setCategorizedProducts",
};
export const actionTypes = {
  changeFilter: "action/changeFilter",
  replaceFilters: "action/replaceFilters",
  changeSortBy: "action/changeSortBy",
  clearFilters: "action/clearFilters",
  getFiltredProducts: "action/getFiltredProducts",

  getCategories: "action/getCategories",
  getPdfProducts: "action/getPdfProducts",
  getAllProducts: "mutation/getAllProducts",
  // getCategorizedProducts: "mutation/getCategorizedProducts",
};

export const catalogModule = {
  namespaced: true,
  state: () => ({
    data: [],
    meta: [],
    allProducts: [],
    filters: { categories: [], shopTypes: [], discountTypes: [] },
    sortBy: null,
    error: null,
    isLoading: false,
    categories: [],
    pdfProducts: [],
    // categorizedProducts: [],
  }),

  mutations: {
    [mutationTypes.addFilter](state, payload) {
      switch (payload.type) {
        case "categories":
          state.filters.categories.push(payload.filterId);
          return;
        case "shopTypes":
          state.filters.shopTypes.push(payload.filterId);
          return;
        case "discountTypes":
          state.filters.discountTypes.push(payload.filterId);
          return;
      }
    },

    [mutationTypes.setFilters](state, payload) {
      state.filters = payload;
    },

    [mutationTypes.removeFilter](state, payload) {
      switch (payload.type) {
        case "categories":
          state.filters.categories.splice(payload.foundFilterIndex, 1);
          return;
        case "shopTypes":
          state.filters.shopTypes.splice(payload.foundFilterIndex, 1);
          return;
        case "discountTypes":
          state.filters.discountTypes.splice(payload.foundFilterIndex, 1);
          return;
      }
    },

    [mutationTypes.clearFilters](state) {
      state.filters.categories = [];
      state.filters.shopTypes = [];
      state.filters.discountTypes = [];
    },
    [mutationTypes.addSortBy](state, payload) {
      state.sortBy = payload;
    },
    [mutationTypes.clearSortBy](state) {
      state.sortBy = null;
    },
    [mutationTypes.clearSortBy](state) {
      state.sortBy = null;
    },

    [mutationTypes.loadProductsStart](state) {
      state.isLoading = true;
      state.error = null;
    },

    [mutationTypes.loadProductsSuccess](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.meta = payload.data.meta;
      switch (payload.type) {
        case "push":
          state.data = [...state.data, ...payload.data.data];
          return;
        case "replace":
          state.data = payload.data.data;
      }
    },

    [mutationTypes.loadProductsFailure](state, payload) {
      state.isLoading = false;
      state.data = [];
      state.error = payload.data.data;
    },

    [mutationTypes.setCategories](state, payload) {
      state.categories = payload;
    },

    [mutationTypes.setPdfProducts](state, payload) {
      state.pdfProducts = payload;
    },
    [mutationTypes.setAllProducts](state, payload) {
      state.allProducts = payload;
    },
  },
  actions: {
    [actionTypes.changeFilter]({ commit, state }, { type, filterId }) {
      const foundFilterIndex = state.filters[type].findIndex(
        (id) => id === filterId
      );
      if (foundFilterIndex === -1) {
        commit(mutationTypes.addFilter, { type, filterId });
        return;
      }
      commit(mutationTypes.removeFilter, { type, foundFilterIndex });
    },
    [actionTypes.replaceFilters]({ commit }, payload) {
      commit(mutationTypes.setFilters, payload);
    },
    [actionTypes.clearFilters]({ commit }) {
      commit(mutationTypes.clearFilters);
    },

    [actionTypes.getFiltredProducts]({ commit, state, rootState }, payload) {
      commit(mutationTypes.loadProductsStart);
      return new Promise((resolve) => {
        getProductsByFilters(
          state.filters,
          state.sortBy,
          payload?.pagination,
          payload?.cityId || rootState.cityModule.selectedCity?.id || 2
        )
          .then((response) => {
            if (payload?.isPaginate) {
              commit(mutationTypes.loadProductsSuccess, {
                type: "push",
                data: response,
              });
            } else {
              commit(mutationTypes.loadProductsSuccess, {
                type: "replace",
                data: response,
              });
            }

            resolve(response);
          })
          .catch(() => {
            commit(mutationTypes.loadProductsFailure);
          });
      });
    },

    [actionTypes.changeSortBy]({ commit }, payload) {
      commit(mutationTypes.addSortBy, payload);
    },

    [actionTypes.getCategories]({ commit }, payload) {
      commit(mutationTypes.setCategories, payload);
    },

    [actionTypes.getPdfProducts]({ commit }, payload) {
      commit(mutationTypes.setPdfProducts, payload);
    },

    [actionTypes.getAllProducts]({ commit }, payload) {
      commit(mutationTypes.setAllProducts, payload);
    },
  },
  getters: {
    isFilterEmpty: (state) => {
      const { categories, shopTypes, discountTypes } = state.filters;

      return categories.length || shopTypes.length || discountTypes.length;
    },
  },
};
