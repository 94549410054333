export const mutationTypes = {
  setAnchor: "mutation/setAnchor",
  setPromo: "mutation/setPromo",
};
export const actionTypes = {
  getAnchor: "action/getAnchor",
  getPromo: "action/getPromo",
};

export const knivesModule = {
  namespaced: true,
  state: () => ({
    anchor: null,
    promo: null,
    knifes: [
      {
        id: 1,
        title: "knives.products.product_5.title",
        text: "knives.products.product_5.text",
        compound: "knives.products.product_5.compound",
        oldPrice: "30 870",
        newPrice: "4 999",
        discount: "-84%",
        image: require("@/" + "assets/img/knives/knife-5.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/R57LGculdC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 2,
        title: "knives.products.product_3.title",
        text: "knives.products.product_3.text",
        compound: "knives.products.product_3.compound",
        oldPrice: "30 313",
        newPrice: "4 999",
        discount: "-85%",
        image: require("@/" + "assets/img/knives/knife-3.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/5LbiPM5O5Ic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },

      {
        id: 3,
        title: "knives.products.product_2.title",
        text: "knives.products.product_2.text",
        compound: "knives.products.product_2.compound",
        oldPrice: "18 037",
        newPrice: "2 999",
        discount: "-85%",
        image: require("@/" + "assets/img/knives/knife-2.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/xL27ovy3NGs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 4,
        title: "knives.products.product_7.title",
        text: "knives.products.product_7.text",
        compound: "knives.products.product_7.compound",
        oldPrice: "38 259",
        newPrice: "5 399",
        discount: "-86%",
        image: require("@/" + "assets/img/knives/knife-7.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/_KSHMFB92S0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },

      {
        id: 5,
        title: "knives.products.product_4.title",
        text: "knives.products.product_4.text",
        compound: "knives.products.product_4.compound",
        oldPrice: "30 313",
        newPrice: "4 999",
        discount: "-85%",
        image: require("@/" + "assets/img/knives/knife-4.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/b_-0M_H4BcQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },

      {
        id: 6,
        title: "knives.products.product_6.title",
        text: "knives.products.product_6.text",
        compound: "knives.products.product_6.compound",
        oldPrice: "30 381",
        newPrice: "4 999",
        discount: "-87%",
        image: require("@/" + "assets/img/knives/knife-6.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/xtOBFNTdlEg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 7,
        title: "knives.products.product_10.title",
        text: "knives.products.product_10.text",
        compound: "knives.products.product_10.compound",
        oldPrice: "26 006",
        newPrice: "12 999",
        discount: "-50%",
        image: require("@/" + "assets/img/knives/knife-10.png"),
      },
      {
        id: 8,
        title: "knives.products.product_9.title",
        text: "knives.products.product_9.text",
        compound: "knives.products.product_9.compound",
        oldPrice: "16 515",
        newPrice: "5 499",
        discount: "-67%",
        image: require("@/" + "assets/img/knives/knife-9.png"),
      },
      {
        id: 9,
        title: "knives.products.product_1.title",
        text: "knives.products.product_1.text",
        compound: "knives.products.product_1.compound",
        oldPrice: "16 048",
        newPrice: "2 499",
        discount: "-84%",
        image: require("@/" + "assets/img/knives/knife-1.png"),
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/VJKK8y3mhng" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 10,
        title: "knives.products.product_8.title",
        text: "knives.products.product_8.text",
        compound: "knives.products.product_8.compound",
        oldPrice: "26 037",
        newPrice: "4 999",
        discount: "-81%",
        image: require("@/" + "assets/img/knives/knife-8.png"),
      },
    ],
    recipes: [
      {
        id: 1,
        title: "knives.recipes.recipe_1.title",
        instruction: "knives.recipes.recipe_1.instruction",
        ingredients: "knives.recipes.recipe_1.ingredients",
        complexity: 2,
        image: `https://i.ytimg.com/vi/G8GauCJhFfA/maxresdefault.jpg`,
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/G8GauCJhFfA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 2,
        title: "knives.recipes.recipe_2.title",
        instruction: "knives.recipes.recipe_2.instruction",
        ingredients: "knives.recipes.recipe_2.ingredients",
        complexity: 2,
        image: `https://i.ytimg.com/vi/91y_aYNkU0A/maxresdefault.jpg`,
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/91y_aYNkU0A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 3,
        title: "knives.recipes.recipe_3.title",
        instruction: "knives.recipes.recipe_3.instruction",
        ingredients: "knives.recipes.recipe_3.ingredients",
        complexity: 2,
        image: `https://i.ytimg.com/vi/bc7XfVLHNTQ/hqdefault.jpg`,
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/bc7XfVLHNTQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 4,
        title: "knives.recipes.recipe_4.title",
        instruction: "knives.recipes.recipe_4.instruction",
        ingredients: "knives.recipes.recipe_4.ingredients",
        complexity: 2,
        image: `https://i.ytimg.com/vi/J2d-0KrfS4I/maxresdefault.jpg`,
        youtube: `<iframe width="560" height="315" src="https://www.youtube.com/embed/J2d-0KrfS4I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
      },
      {
        id: 5,
        title: "knives.recipes.recipe_5.title",
        instruction: "knives.recipes.recipe_5.instruction",
        ingredients: "knives.recipes.recipe_5.ingredients",
        complexity: 2,
        image: require("@/" + "assets/img/knives/recipe-5.jpg"),
      },
      {
        id: 6,
        title: "knives.recipes.recipe_6.title",
        instruction: "knives.recipes.recipe_6.instruction",
        ingredients: "knives.recipes.recipe_6.ingredients",
        complexity: 2,
        image: require("@/" + "assets/img/knives/recipe-6.jpg"),
      },
      {
        id: 7,
        title: "knives.recipes.recipe_7.title",
        instruction: "knives.recipes.recipe_7.instruction",
        ingredients: "knives.recipes.recipe_7.ingredients",
        complexity: 2,
        image: require("@/" + "assets/img/knives/recipe-7.jpg"),
      },
    ],
  }),

  mutations: {
    [mutationTypes.setAnchor](state, payload) {
      state.anchor = payload;
    },
    [mutationTypes.setPromo](state, payload) {
      state.promo = payload;
    },
  },
  actions: {
    [actionTypes.getAnchor]({ commit }, payload) {
      commit(mutationTypes.setAnchor, payload);
    },

    [actionTypes.getPromo]({ commit }, payload) {
      commit(mutationTypes.setPromo, payload);
    },
  },
};
