export const mutationTypes = {
  setEmailGroups: "mutation/setEmailGroups",
};
export const actionTypes = {
  getEmailGroups: "action/getEmailGroups",
};

export const contactsModule = {
  namespaced: true,
  state: () => ({
    emailGroups: [],
  }),
  mutations: {
    [mutationTypes.setEmailGroups](state, payload) {
      state.emailGroups = payload;
    },
  },
  actions: {
    [actionTypes.getEmailGroups]({ commit }, payload) {
      commit(mutationTypes.setEmailGroups, payload);
    },
  },
};
