import axios from "@/plugins/axios";
import qs from "qs";

export const getAllNews = async (filter, cityId) => {
  if (!cityId) {
    cityId =
      localStorage.key("selectedCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectedCity"))?.id
        : 2;
  }
  let queryFilters = {
    filters: {
      cities: {
        id: { $in: cityId },
      },
    },
  };
  if (filter) {
    queryFilters.filters["news_category"] = {
      id: { $in: filter },
    };
  }
  const query = qs.stringify(
    {
      sort: [`publishedAt:desc`],

      populate: {
        news_category: "*",
        shops: "*",
        image: "*",
        localizations: "*",
        cities: "*",
      },
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/news?${query}`);
  return response.data.data;
};

export const getNewsByCatId = async (catId, cityId) => {
  if (!cityId) {
    cityId = localStorage.getItem("selectedCity")
      ? JSON.parse(localStorage.getItem("selectedCity"))?.id
      : 2;
  }

  const query = qs.stringify({
    sort: [`publishedAt:desc`],
    populate: {
      news_category: "*",
      image: "*",
      localizations: "*",
    },
    filters: {
      news_category: {
        id: { $eq: catId },
      },
      cities: {
        id: { $in: cityId },
      },
    },
  });
  const response = await axios.get(`/news?${query}`);
  return response.data.data;
};

export const getNewsById = async (id) => {
  const query = qs.stringify({
    populate: {
      news_category: "*",
      shops: "*",
      image: "*",
      localizations: {
        populate: {
          image: "*",
        },
      },
    },
  });
  const response = await axios.get(`/news/${id}?${query}`);
  return response.data.data;
};

export const getArticles = async (page = 1, pageSize = 500) => {
  const query = qs.stringify(
    {
      pagination: {
        page,
        pageSize,
      },
      populate: {
        image: "*",
        // localizations: "*",
      },
      filters: {
        Type: {
          $eq: "Food article",
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/news?${query}`);
  return response.data;
};

export const getNewsCategory = async () => {
  const query = qs.stringify(
    {
      populate: {
        // localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/news-categories?${query}`);
  return response?.data?.data;
};
