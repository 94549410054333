import axiosUnintersepter from "axios";
const http = axiosUnintersepter.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  timeout: 100000,
});
http.defaults.params = {};

let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "ru";
if (lang === "kz") {
  lang = "kk";
}

http.interceptors.request.use(
  function (config) {
    // config.params["locale"] = lang; //lang.toLowerCase();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;
