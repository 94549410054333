import axios from "@/plugins/axios";
import axiosOriginal from "@/plugins/axiosUninterseptor";
import qs from "qs";

export const getAllCities = async (sortType = "asc") => {
  const query = qs.stringify(
    {
      sort: [`name:${sortType}`],
      populate: {
        localizations: "*",
        image: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/cities?${query}`);
  return response?.data?.data;
};

export const getShopsByCityId = async (id, sortType = "asc") => {
  const query = qs.stringify(
    {
      populate: {
        shops: {
          sort: [`publishedAt:${sortType}`],
          populate: {
            shop_type: {
              populate: ["logo"],
            },
            shop_features: {
              populate: "*",
            },
          },
        },
        localizations: "*",
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/cities/${id}?${query}`);
  return response?.data.data?.attributes?.shops?.data;
};

export const getBanners = async (cityId) => {
  if (!cityId) {
    cityId = localStorage.getItem("selectedCity")
      ? JSON.parse(localStorage.getItem("selectedCity"))?.id
      : 2;
  }
  const query = qs.stringify({
    populate: {
      localizations: "*",
      image_mob: "*",
      image: "*",
    },
    sort: ["order:asc"],
    filters: {
      cities: {
        id: { $eq: cityId },
      },
    },
  });
  const response = await axios.get(`/banners?${query}`);
  return response?.data?.data;
};
export const getPromos = async (stock, pagination, excludeStockId) => {
  let date = new Date().toLocaleDateString("ru", { day: "2-digit" });
  let month = new Date().toLocaleDateString("ru", { month: "2-digit" });
  let year = new Date().toLocaleDateString("ru", { year: "numeric" });
  const selectedCityId = localStorage.getItem("selectedCity")
    ? JSON.parse(localStorage.getItem("selectedCity"))?.id
    : 2;
  const today = `${year}-${month}-${date}`;

  let filters = {};
  if (stock?.type === "current") {
    filters = {
      $or: [
        {
          $and: [
            { date_end: { $gte: today } },
            { date_start: { $lte: today } },
          ],
        },
        { date_end: { $null: true }, date_start: { $null: true } },
      ],
    };
  }

  if (stock?.type === "announcement") {
    filters["date_start"] = { $gt: today };
  }

  if (stock?.type === "completed") {
    filters["date_end"] = { $lt: today };
  }
  if (excludeStockId) {
    filters["id"] = { $ne: excludeStockId };
  }
  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 500,
      },
      populate: {
        preview_desktop: "*",
        preview_mob: "*",
        discount: "*",
        shops: {
          populate: "*",
        },
        localizations: {
          populate: {
            discount: "*",
            preview_desktop: "*",
            preview_mob: "*",
          },
        },
      },
      sort: stock?.type === "completed" ? ["date_end:desc"] : ["order:asc"],
      filters: { ...filters, cities: { id: { $eq: selectedCityId } } },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/promos?${query}`);
  return response?.data;
};

export const getPromo = async (id, cityId) => {
  const query = qs.stringify(
    {
      populate: {
        backgound_desktop: "*",
        backgound_mob: "*",
        discount: {
          populate: {
            discount_type: "*",
          },
        },
        all_products: "*",
        showcase_bulks: {
          populate: "*",
          showcases: {
            image: "*",
            localizations: "*",
            internal_name: "*",
            label: "*",
            showcase_bulks: "*",
            richtext: "*",
          },
        },
        localizations: {
          populate: {
            backgound_desktop: "*",
            backgound_mob: "*",
          },
        },
        filters: {
          cities: {
            id: { $in: cityId },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const response = await axios.get(`/promos/${id}?${query}`);
  return response?.data;
};

export const getIIN = async (IIN) => {
  const response = await axiosOriginal.get(`/gsp/info?iin=${IIN}`);
  return response?.data;
};
