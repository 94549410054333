import { extend } from "vee-validate";
import { required, email, alpha, length } from "vee-validate/dist/rules";

// No message specified.
extend("email", { ...email, message: "email" });

// Override the default message.
extend("required", {
  ...required,
  message: "required",
});
extend("alpha", {
  ...alpha,
  message: "alpha",
});
extend("length", {
  ...length,
  message: "length",
});
