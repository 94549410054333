export const mutationTypes = {
  removeFilter: "mutation/removeFilter",
  addFilters: "mutation/addFilters",
  addFilteredVacancy: "mutation/addFilteredVacancy",
  addVacancySelect: "mutation/addVacancySelect",
};
export const actionTypes = {
  deleteFilter: "action/deleteFilter",
  changeFilters: "action/changeFilters",
  changeFilteredVacancy: "action/changeFilteredVacancy",
  changeVacancySelect: "action/changeVacancySelect",
};

export const vacancyModule = {
  namespaced: true,
  state: {
    resetFilter: true,
    filters: null,
    filteredVacancy: null,
    vacancySelect: null,
  },

  mutations: {
    [mutationTypes.removeFilter](state, payload) {
      state.resetFilter = payload;
    },
    [mutationTypes.addFilters](state, payload) {
      state.filters = payload;
    },
    [mutationTypes.addFilteredVacancy](state, payload) {
      state.filteredVacancy = payload;
    },
    [mutationTypes.addVacancySelect](state, payload) {
      state.vacancySelect = payload;
    },
  },
  actions: {
    [actionTypes.deleteFilter]({ commit }, payload) {
      commit(mutationTypes.removeFilter, payload);
    },
    [actionTypes.changeFilters]({ commit }, payload) {
      commit(mutationTypes.addFilters, payload);
    },

    [actionTypes.changeFilteredVacancy]({ commit }, payload) {
      commit(mutationTypes.addFilteredVacancy, payload);
    },

    [actionTypes.changeVacancySelect]({ commit }, payload) {
      commit(mutationTypes.addVacancySelect, payload);
    },
  },
};
