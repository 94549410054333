import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "@/plugins/i18n";
// import { eventBus } from "@/helpers";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang(ru|kz)?",
    component: {
      template: "<router-view />",
    },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "catalog",
        name: "catalog",
        component: () => import("@/views/products/PageCatalog"),
      },
      {
        path: "forum",
        name: "forum",
        component: () => import("@/views/Forum"),
      },
      {
        path: "partners",
        name: "partners",
        component: () => import("@/views/Partners"),
      },
      {
        path: "cooking",
        name: "cooking",
        component: () => import("@/views/cooking/PageCooking"),
      },
      {
        path: "articles/:id",
        name: "articleItem",
        component: () => import("@/views/cooking/FullArticle"),
      },
      {
        path: "recipes",
        props: true,
        name: "recipes",
        component: () => import("@/views/recipes/PageRecipes"),
      },
      {
        path: "recipes/:id",
        name: "recipeItem",
        props: true,
        component: () => import("@/views/recipes/FullRecipe"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("@/views/news/PageNews"),
      },
      {
        path: "news/:id",
        name: "newsItem",
        component: () => import("@/views/news/FullNews"),
        props: true,
      },
      {
        path: "own-production",
        name: "ownProduction",
        component: () => import("@/views/own-production/PageOwnProduction"),
        meta: {
          layout: "fullscreen-layout",
        },
        props: true,
      },
      {
        path: "private-label",
        name: "privateLabel",
        component: () => import("@/views/private-label/PagePrivateLabel"),
        props: true,
        meta: {
          layout: "fullscreen-layout",
        },
      },
      {
        path: "shops",
        name: "shops",
        component: () => import("@/views/shops/PageShops"),
      },
      {
        path: "shops/:id",
        name: "shopItem",
        component: () => import("@/views/shops/FullShop"),
        props: true,
      },
      {
        path: "search",
        name: "search",
        component: () => import("@/views/SearchResult"),
      },
      {
        path: "stocks",
        name: "stocks",
        component: () => import("@/views/stocks/PageStocks"),
      },
      {
        path: "stocks/:id",
        name: "stockItem",
        props: true,
        component: () => import("@/views/stocks/FullStocks"),
      },
      {
        path: "stocks/products/:id",
        name: "stockOnlyDiscount",
        props: true,
        component: () => import("@/views/stocks/PageStocksProducts"),
      },
      {
        path: "products/:id",
        name: "fullProduct",
        props: true,
        component: () => import("@/views/products/FullProduct"),
      },
      {
        path: "magnumclub",
        name: "MagnumClub",
        component: () => import("@/views/MagnumClub"),
      },
      {
        path: "magnumgo",
        name: "MagnumGo",
        component: () => import("@/views/MagnumGo"),
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/views/about/AboutCompany"),
      },
      {
        path: "career",
        name: "MagnumCareer",
        component: () => import("@/views/MagnumCareer"),
      },
      {
        path: "vacancy/:id",
        name: "FullVacancy",
        component: () => import("@/views/FullVacancy"),
        props: true,
      },
      {
        path: "contacts",
        name: "Contacts",
        component: () => import("@/views/Contacts"),
      },
      {
        path: "appeal",
        name: "Appeal",
        component: () => import("@/views/modals/ModalAppealPage"),
      },
      {
        path: "appeal/:id",
        name: "Appeal",
        component: () => import("@/views/modals/ModalAppealPage"),
        props: true,
      },
      {
        path: "corp",
        name: "MagnumCorp",
        component: () => import("@/views/MagnumCorp"),
      },
      {
        path: "gsp",
        name: "MagnumGsp",
        component: () => import("@/views/gsp/MagnumGsp"),
      },
      {
        path: "storage/_Pages/Magnum_Club/91b9c206-40af-4c9b-9a29-5d8f208d7387.pdf",
        name: "Storage",
        component: () => import("@/views/Storage"),
      },
      {
        path: "storage/uploads/Magnum%20Club/91b9c206-40af-4c9b-9a29-5d8f208d7387.pdf",
        name: "Storage",
        component: () => import("@/views/Storage"),
      },
      {
        path: "compliance",
        name: "Compliance",
        component: () => import("@/views/ComplianceService"),
      },
      {
        path: "special_promo",
        name: "MagnumPromo",
        component: () => import("@/views/MagnumPromo"),
      },
      {
        path: "interview",
        name: "Interview",
        component: () => import("@/views/Interview"),
      },
      {
        path: "akciya_nozhi",
        name: "knives",
        meta: {
          layout: "knives-layout",
        },
        component: () => import("@/views/PromotionKnives"),
      },
      // {
      //   path: "page/slujba-complaince",
      //   name: "ComplianceService",
      //   component: () => import("@/views/ComplianceService"),
      // },
      {
        path: "akciya_nozhi",
        redirect: () => ({
          name: "knives",
          params: { lang: "ru", redirect: true },
        }),
      },
      {
        path: "post/vyigryvaite-kvartiru-i-1000-*",
        redirect: (to) => ({
          name: "stockItem",
          params: { id: 10, lang: "ru" },
          query: {
            utm_source: to.params.utm_source,
            utm_medium: to.params.utm_medium,
          },
        }),
      },
      {
        path: "page/arendatoram*",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/arendodatelyam-i-sobstvennikam",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/postavshikam*",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/reklamodatelyam*",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },

      {
        path: "page/telefon-doveriya*",
        redirect: (to) => ({
          name: "Contacts",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/magnum-club*",
        redirect: (to) => ({
          name: "MagnumClub",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "magnumclub/*",
        redirect: (to) => ({
          name: "MagnumClub",
          params: {
            redirect: true,
            appPage: to.query.type
              ? to.path.split("/").slice(2).join("/") +
                `?type=${to.query.type}&actor=${to.query.actor}&sort=${to.query.sort}`
              : to.path.split("/").slice(3).join("/"),
            app: to.path.split("/").slice(-1).join(""),
          },
        }),
      },
      {
        path: "page/magnum-go*",
        redirect: (to) => ({
          name: "MagnumGo",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/katalog-magnum*",
        redirect: (to) => ({
          name: "catalog",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/torgi-magnum*",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/optovyi-klub-magnum-opt*",
        redirect: (to) => ({
          name: "MagnumCorp",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
      {
        path: "page/karera-v-magnum*",
        redirect: (to) => ({
          name: "MagnumCareer",
          query: {
            utm_source: to.utm_source,
            utm_medium: to.utm_medium,
          },
        }),
      },
    ],
  },

  { path: "*", component: () => import("@/views/404") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    console.log("to", to);
    if ((from.name === "catalog" && to.params.isFilter) || to.params.noScroll) {
      return false;
    }

    // if (from.name === "catalog") {
    //   return false;
    // }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;

  if (!language) {
    language = "ru";
    to.params.lang = language;
  }

  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  i18n.locale = language;
  next();
});

// router.afterEach((to) => {
//   switch (to.name) {
//     case "catalog":
//       eventBus.$emit("gtm", {
//         event: "view_item_list",
//         type: "ecommerce",
//         items: [],
//       });
//   }
// });

export default router;
