import { getPromos } from "@/api";

export const mutationTypes = {
  loadAllStocksStart: "mutation/loadAllStocksStart",
  loadAllStocksSuccess: "mutation/loadAllStocksSuccess",
  loadAllStocksFailure: "mutation/loadAllStocksFailure",
  setStockStatus: "mutation/setStockStatus",
};
export const actionTypes = {
  loadAllStocks: "action/loadAllStocks",
  changeStockStatus: "action/changeStockStatus",
};

export const stockModule = {
  namespaced: true,
  state: () => ({
    data: null,
    stockStatus: null,
    isLoading: false,
    error: null,
  }),

  mutations: {
    [mutationTypes.loadAllStocksStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadAllStocksSuccess](state, payload) {
      state.isLoading = false;
      state.error = null;
      switch (payload.type) {
        case "push":
          state.data.data = [...state.data.data, ...payload.data];
          state.data.meta = payload.meta;
          return;
        case "replace":
          state.data = payload;
          return;
      }
    },
    [mutationTypes.loadAllStocksFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },
    [mutationTypes.saveStockItemMutation](state, payload) {
      state.saveStockItem = payload;
    },
    [mutationTypes.setStockStatus](state, payload) {
      // state.data = null;
      state.stockStatus = payload;
    },
  },
  actions: {
    [actionTypes.loadAllStocks]({ commit, state }, payload) {
      commit(mutationTypes.loadAllStocksStart);

      return new Promise((resolve) => {
        getPromos(state.stockStatus, payload.pagination)
          .then((promos) => {
            commit(mutationTypes.loadAllStocksSuccess, {
              type: payload.type,
              ...promos,
            });

            resolve(promos);
          })
          .catch((e) => {
            commit(mutationTypes.loadAllStocksFailure, e);
          });
      });
    },
    [actionTypes.changeStockStatus]({ commit }, payload) {
      commit(mutationTypes.setStockStatus, payload);
      console.log(payload);
    },
  },
};
